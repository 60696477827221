import * as React from "react"

import PropTypes from 'prop-types'

function VimeoEmbed({ src, title }) {
  return (
    <div className="vimeoEmbed overflow-hidden shadow-lg">
      <div style={{padding:"56.25% 0 0 0",position:"relative"}}><iframe src={src} title={title} frameBorder="0" allow="autoplay; fullscreen; picture-in-picture" allowFullScreen style={{position:"absolute",top:"0",left:"0",width:"100%",height:"100%"}}></iframe></div><script src="https://player.vimeo.com/api/player.js"></script>
    </div>
  )
}

VimeoEmbed.propTypes = {
  src: PropTypes.string.isRequired,
}

export default VimeoEmbed