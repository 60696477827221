import React from 'react'

const Logo = () => (
  <svg className="w-full h-auto" width="248" height="89" viewBox="0 0 248 89" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0)">
      <path d="M28.6016 69.819V69.0325C28.7931 69.0325 28.9654 68.812 29.1282 68.3612C29.3124 67.8464 29.4533 67.3171 29.5495 66.7788C29.6644 66.1554 29.7602 65.5704 29.8272 65.0045C29.89 64.5821 29.9252 64.156 29.9325 63.729V31.7926C29.9242 31.3497 29.8891 30.9077 29.8272 30.4691C29.7602 29.8841 29.6644 29.2799 29.5495 28.6565C29.4533 28.115 29.3123 27.5826 29.1282 27.0645C28.9654 26.6233 28.7931 26.4027 28.6016 26.4027V25.6067H36.2618V26.4027C36.032 26.4027 35.8309 26.6616 35.6681 27.1603C35.4887 27.7288 35.3479 28.3087 35.2468 28.8962C35.1319 29.5484 35.0457 30.1909 34.9979 30.8143C34.95 31.4377 34.9308 31.8405 34.9308 32.0227V40.2898H41.8155V31.8405C41.8026 31.3947 41.7675 30.9498 41.7101 30.5075C41.6335 29.9128 41.5569 29.3086 41.4612 28.6852C41.3888 28.1357 41.2472 27.5976 41.0399 27.0836C40.8579 26.6329 40.6665 26.4123 40.4749 26.4123V25.6163H48.1352V26.4123C47.9437 26.4123 47.7713 26.6233 47.6085 27.0357C47.4243 27.5181 47.2958 28.0201 47.2255 28.5318C47.1298 29.1264 47.0436 29.7018 46.9765 30.2581C46.9095 30.8143 46.8425 31.2747 46.8042 31.6295V63.8728C46.8042 64.2277 46.9095 64.688 46.9765 65.2443C47.0436 65.8005 47.1298 66.3664 47.2255 66.9226C47.2991 67.4212 47.4275 67.9101 47.6085 68.3804C47.7713 68.7928 47.9437 69.0038 48.1352 69.0038V69.819H40.4749V69.0325C40.7143 69.0325 40.9154 68.7736 41.0782 68.2749C41.2578 67.713 41.3986 67.1394 41.4995 66.5582C41.6133 65.9429 41.6932 65.3217 41.7389 64.6976C41.7389 64.1126 41.8155 63.7386 41.8155 63.5468V46.5524H34.9308V63.8728C34.9399 64.3316 34.9752 64.7895 35.0362 65.2443C35.1032 65.8005 35.1989 66.3664 35.3139 66.9226C35.4171 67.4186 35.5579 67.9059 35.7352 68.3804C35.9075 68.7928 36.0799 69.0038 36.2618 69.0038V69.819H28.6016Z" fill="white"/>
      <path d="M51.7065 69.819V69.0325C51.8885 69.0325 52.0704 68.8216 52.2332 68.4092C52.414 67.9224 52.5425 67.4176 52.6162 66.9035C52.712 66.3185 52.7981 65.7334 52.8652 65.1388C52.9228 64.6997 52.9579 64.258 52.9705 63.8153V31.7063C52.9576 31.2636 52.9224 30.8219 52.8652 30.3828C52.7981 29.7978 52.712 29.2031 52.6162 28.6181C52.5384 28.0892 52.4101 27.5689 52.2332 27.0645C52.0704 26.6233 51.8885 26.4027 51.7065 26.4027V25.6067H66.7302L67.3621 33.5668H66.7302C66.7302 33.3303 66.5291 33.1097 66.1269 32.9051C65.6857 32.6819 65.2239 32.5021 64.7481 32.368C64.2616 32.2292 63.7655 32.1266 63.2639 32.0611C62.7915 32.0036 62.4724 31.9748 62.3064 31.9748H57.9209V40.1939H60.0466C60.1903 40.1939 60.5063 40.1939 61.0042 40.1075C61.5198 40.0463 62.0292 39.9405 62.5266 39.7911C63.007 39.6659 63.4726 39.4891 63.915 39.2636C64.3172 39.0654 64.5183 38.816 64.5183 38.5155H65.1502V48.106H64.5183C64.5183 47.8759 64.3172 47.6361 63.915 47.3963C63.4818 47.1505 63.0136 46.9726 62.5266 46.8689C62.0095 46.7538 61.5021 46.6483 61.0042 46.562C60.688 46.4977 60.3682 46.4529 60.0466 46.4277H57.9209V63.4797H62.3064C62.4532 63.4797 62.7724 63.4509 63.2639 63.3933C63.7655 63.3279 64.2616 63.2253 64.7481 63.0864C65.2253 62.9565 65.6875 62.7765 66.1269 62.5493C66.5291 62.3448 66.7302 62.0954 66.7302 61.8013H67.3621L66.7302 69.7518L51.7065 69.819Z" fill="white"/>
      <path d="M87.6904 64.6689C87.767 65.2635 87.8436 65.8773 87.9394 66.5294C88.0195 67.1113 88.1476 67.6855 88.3223 68.2461C88.4851 68.7448 88.6862 69.0038 88.9256 69.0038V69.819H82.2229L75.6925 42.4285V63.8153C75.702 64.2741 75.7372 64.7319 75.7978 65.1867C75.8649 65.743 75.9607 66.3185 76.0756 66.9035C76.1703 67.4141 76.3015 67.9173 76.4681 68.4092C76.6022 68.8216 76.7937 69.0325 77.0235 69.0325V69.819H69.3633V69.0325C69.5931 69.0325 69.7942 68.7736 69.9569 68.2749C70.1366 67.713 70.2773 67.1394 70.3783 66.5582C70.4932 65.906 70.5794 65.2922 70.6272 64.6976C70.6751 64.103 70.6943 63.7386 70.6943 63.5468V31.9748C70.6943 31.7926 70.6943 31.3994 70.6272 30.776C70.5602 30.1526 70.4932 29.5292 70.3783 28.8579C70.2773 28.2766 70.1366 27.703 69.9569 27.1412C69.7942 26.6425 69.5931 26.3835 69.3633 26.3835V25.5875H76.1713L82.5676 52.1916V31.9556C82.5676 31.7734 82.5676 31.3802 82.491 30.7568C82.4144 30.1334 82.3665 29.51 82.2516 28.8387C82.1461 28.2584 82.0054 27.6851 81.8303 27.122C81.658 26.6233 81.4665 26.3643 81.2271 26.3643V25.5683H88.8873V26.3643C88.6479 26.3643 88.4468 26.6233 88.2841 27.122C88.1093 27.6826 87.9812 28.2568 87.901 28.8387C87.8053 29.4908 87.7287 30.1238 87.6521 30.7568C87.5755 31.3897 87.5467 31.7734 87.5467 31.9556V63.4988C87.585 63.7002 87.6234 64.0647 87.6904 64.6689Z" fill="white"/>
      <path d="M107.664 64.4003C107.808 64.8127 107.971 65.2923 108.162 65.8198C108.354 66.3472 108.545 66.846 108.756 67.3159C108.935 67.7388 109.16 68.1408 109.426 68.5147C109.497 68.6448 109.597 68.7566 109.718 68.8414C109.84 68.9263 109.979 68.9819 110.125 69.0038V69.819H102.465V69.0326C102.791 69.0326 102.934 68.5243 102.886 67.5269C102.849 66.5595 102.731 65.5971 102.532 64.6497L99.2094 52.9013H97.6678V63.681C97.6807 64.1237 97.7159 64.5654 97.7731 65.0045C97.8402 65.5896 97.9263 66.1842 98.0221 66.7692C98.0952 67.296 98.2236 67.8135 98.4051 68.3133C98.5679 68.764 98.7403 68.9846 98.9318 68.9846V69.819H91.3481V69.0326C91.5301 69.0326 91.7215 68.7928 91.9131 68.3229C92.1077 67.8081 92.2489 67.2746 92.3344 66.7309C92.4205 66.1459 92.5067 65.5512 92.5738 64.9662C92.636 64.5244 92.6712 64.0792 92.6791 63.6331V31.8885C92.6791 31.7063 92.6791 31.3131 92.612 30.6897C92.545 30.0663 92.478 29.4429 92.3631 28.7716C92.2609 28.1875 92.1202 27.6108 91.9418 27.0453C91.779 26.5466 91.578 26.2972 91.3481 26.2972V25.5875H101.603C102.816 25.6941 103.979 26.121 104.974 26.8247C105.937 27.6199 106.734 28.5976 107.32 29.7019C108.145 31.4685 108.52 33.4127 108.411 35.3603V41.6324C108.411 45.5837 107.562 48.56 105.864 50.5612C105.447 51.1076 104.918 51.5587 104.313 51.8847L107.664 64.4003ZM101.259 46.6387C101.452 46.6283 101.643 46.5862 101.824 46.514C102.12 46.3691 102.367 46.1387 102.532 45.8523C102.816 45.3913 103.02 44.8854 103.135 44.3562C103.327 43.4908 103.411 42.6049 103.384 41.7188V35.4849C103.445 34.9992 103.404 34.5061 103.264 34.0371C103.124 33.5681 102.888 33.1336 102.57 32.7612C102.17 32.3807 101.698 32.084 101.182 31.8885H97.6678V46.6387H101.259Z" fill="white"/>
      <path d="M112.442 30.4978C112.072 29.5763 111.65 28.6765 111.178 27.8029C110.671 26.8439 110.221 26.3452 109.915 26.3452V25.6354H117.853V26.3452C117.613 26.3452 117.517 26.6233 117.537 27.1891C117.56 27.796 117.618 28.4012 117.709 29.0017C117.852 29.7594 118.015 30.6513 118.207 31.6487L119.471 38.5443L120.802 31.5624C120.984 30.6033 121.156 29.7594 121.29 29.0017C121.424 28.2441 121.443 27.7453 121.472 27.1891C121.501 26.6328 121.386 26.3452 121.156 26.3452V25.6354H129.017V26.3452C128.692 26.3452 128.29 26.8055 127.82 27.7166C127.351 28.6277 126.93 29.5004 126.556 30.3252L121.999 49.9378V63.633C122.009 64.0472 122.044 64.4603 122.104 64.8703C122.171 65.4649 122.257 66.069 122.343 66.6828C122.425 67.2402 122.556 67.7891 122.736 68.3228C122.899 68.7928 123.1 69.0325 123.33 69.0325V69.8189H115.669V69.0325C115.851 69.0325 116.033 68.8119 116.196 68.3612C116.374 67.8574 116.514 67.3414 116.617 66.8171C116.732 66.2321 116.828 65.6375 116.895 65.0525C116.952 64.6134 116.987 64.1716 117 63.729V49.909L112.442 30.4978Z" fill="white"/>
      <path d="M141.168 69.819V69.0325C141.35 69.0325 141.532 68.8408 141.695 68.4571C141.872 68.0153 142.001 67.5555 142.078 67.0857C142.174 66.5582 142.25 66.0115 142.327 65.4457C142.404 64.8799 142.452 64.4291 142.499 64.0839V31.4569C142.452 31.1021 142.394 30.6417 142.327 30.0855C142.26 29.5292 142.174 28.9634 142.078 28.4071C142.005 27.9208 141.877 27.4445 141.695 26.9877C141.532 26.6041 141.35 26.4123 141.168 26.4123V25.6163H152.822C153.707 25.6147 154.578 25.8427 155.349 26.278C156.148 26.7287 156.85 27.3315 157.418 28.0523C158.036 28.8326 158.512 29.7155 158.825 30.6609C159.181 31.681 159.359 32.7548 159.352 33.8354V61.6478C159.36 62.7285 159.182 63.8024 158.825 64.8223C158.514 65.7686 158.038 66.652 157.418 67.4309C156.859 68.1479 156.154 68.7365 155.349 69.1572C154.569 69.5647 153.701 69.7754 152.822 69.771L141.168 69.819ZM147.488 63.5468H152.822C153.036 63.5439 153.247 63.4902 153.436 63.3902C153.626 63.2902 153.789 63.1466 153.913 62.9713C154.21 62.6095 154.369 62.1544 154.363 61.6862V33.7778C154.387 33.2924 154.226 32.8159 153.913 32.4447C153.782 32.2804 153.616 32.1477 153.427 32.0563C153.238 31.965 153.031 31.9175 152.822 31.9173H147.459L147.488 63.5468Z" fill="white"/>
      <path d="M162.722 69.819V69.0325C162.914 69.0325 163.086 68.7928 163.249 68.3229C163.43 67.7876 163.571 67.2393 163.67 66.6829C163.785 66.0834 163.868 65.4784 163.919 64.8703C163.919 64.2853 163.986 63.9112 163.986 63.6331V31.5336C163.977 31.0748 163.942 30.617 163.881 30.1622C163.814 29.6059 163.718 29.0401 163.603 28.4838C163.502 27.9912 163.374 27.5046 163.22 27.0261C163.076 26.6137 162.914 26.4027 162.722 26.4027V25.6067H170.239V26.4027C169.999 26.4027 169.818 26.6425 169.674 27.1124C169.53 27.5823 169.406 28.129 169.291 28.7428C169.178 29.3425 169.095 29.9474 169.042 30.5554C169.042 31.15 168.975 31.5624 168.975 31.7926V63.6043C168.975 63.8441 168.975 64.2565 169.042 64.8415C169.093 65.4496 169.176 66.0547 169.291 66.6541C169.406 67.2775 169.54 67.8241 169.674 68.2941C169.808 68.764 169.999 69.0038 170.239 69.0038V69.819H162.722Z" fill="white"/>
      <path d="M173.188 69.819V69.0325C173.37 69.0325 173.552 68.8216 173.715 68.4092C173.901 67.9213 174.042 67.4172 174.136 66.9035C174.251 66.3184 174.347 65.7334 174.414 65.1388C174.472 64.6997 174.507 64.258 174.519 63.8153V31.4569C174.519 31.102 174.414 30.6417 174.347 30.0855C174.28 29.5292 174.194 28.9825 174.098 28.4455C174.017 27.9641 173.888 27.492 173.715 27.0357C173.552 26.6233 173.37 26.4123 173.188 26.4123V25.6163H180.849V26.4123C180.619 26.4123 180.418 26.6712 180.255 27.1699C180.075 27.7318 179.935 28.3054 179.834 28.8866C179.719 29.5388 179.633 30.1526 179.585 30.7472C179.537 31.3418 179.518 31.7062 179.518 31.898V63.5468H183.846C184.168 63.5264 184.488 63.4815 184.803 63.4125C185.292 63.3262 185.761 63.2303 186.307 63.1056C186.783 63.0057 187.244 62.8411 187.676 62.6165C188.078 62.4119 188.279 62.1626 188.279 61.8684H188.834L187.877 69.819H173.188Z" fill="white"/>
      <path d="M191.506 31.9268C191.036 32.0132 190.548 32.1187 190.031 32.2337C189.55 32.3422 189.088 32.5233 188.662 32.7708C188.26 33.001 188.068 33.2408 188.068 33.4709H187.436V31.7926L188.394 25.5204H204.193L205.15 31.7926V33.4709H204.518C204.518 33.2408 204.317 33.001 203.925 32.7708C203.497 32.5249 203.036 32.3439 202.555 32.2337C202.038 32.1187 201.55 32.0132 201.081 31.9268C200.765 31.8599 200.445 31.8151 200.123 31.7926H198.725V63.518C198.733 63.9609 198.768 64.403 198.831 64.8415C198.898 65.4361 198.984 66.0403 199.07 66.6541C199.151 67.2115 199.283 67.7603 199.463 68.2941C199.625 68.764 199.826 69.0038 200.056 69.0038V69.819H192.396V69.0326C192.578 69.0326 192.76 68.812 192.923 68.3612C193.1 67.8575 193.241 67.3414 193.344 66.8172C193.459 66.2321 193.555 65.6376 193.622 65.0525C193.679 64.6134 193.714 64.1717 193.727 63.729V31.7926H192.463C192.141 31.8156 191.821 31.8605 191.506 31.9268V31.9268Z" fill="white"/>
      <path d="M210.963 32.0132C210.493 32.1091 210.005 32.205 209.488 32.3297C209.007 32.4323 208.545 32.6103 208.119 32.8571C207.726 33.0969 207.525 33.3271 207.525 33.5668H206.893L207.851 25.6067H221.543L222.099 28.4838L212.274 63.5564H215.712C215.859 63.5564 216.178 63.5276 216.67 63.4701C217.181 63.4066 217.687 63.304 218.182 63.1632C218.651 63.0357 219.108 62.8722 219.552 62.6741C219.944 62.5014 220.145 62.2617 220.145 61.9644H220.71L219.753 69.8286H206.127L205.495 67.009L215.329 31.9268H211.891C211.58 31.9329 211.27 31.9617 210.963 32.0132V32.0132Z" fill="white"/>
      <path d="M84.5208 6.33932C84.5208 9.84945 82.3089 12.6787 79.5991 12.6787C76.8893 12.6787 74.687 9.84945 74.687 6.33932C74.687 2.82919 76.8893 0 79.5991 0C82.3089 0 84.5208 2.85796 84.5208 6.33932ZM81.2269 5.88856C80.8344 3.10731 79.7619 1.09331 78.881 1.3139C78.0001 1.53448 77.5788 4.04718 77.9235 6.81884C78.2682 9.5905 79.3885 11.6141 80.279 11.3839C81.1695 11.1538 81.6291 8.66022 81.2269 5.88856V5.88856Z" fill="white"/>
      <path d="M93.2056 11.1633C93.3684 11.3839 93.5791 11.4894 93.5503 11.7963C93.4737 12.554 92.43 12.6787 91.7214 12.6787C91.0129 12.6787 89.8064 12.2758 89.8064 10.2043V6.72293C89.8064 3.58683 88.169 6.38728 88.1786 8.50678C88.1063 9.41693 88.2644 10.3306 88.6382 11.1633C88.801 11.4127 89.0116 11.4894 88.9733 11.7963C88.9063 12.554 87.5179 12.6787 86.8093 12.6787C86.1007 12.6787 84.7889 12.554 84.7123 11.7963C84.7123 11.4607 84.8751 11.336 85.0379 11.1154C85.4431 9.92201 85.4699 8.63227 85.1145 7.42305C84.9326 7.09697 84.674 6.89557 84.7123 6.52154C84.7889 5.76389 85.7273 5.22681 86.3593 4.77606C86.9913 4.3253 88.7052 3.10732 88.4371 4.72812L88.3414 5.48578C88.7818 4.39246 89.4809 3.91292 89.9692 3.91292C92.6215 3.91292 92.3343 9.92617 93.2056 11.1633Z" fill="white"/>
      <path d="M105.376 8.53557C105.615 10.7606 103.461 12.6787 101.038 12.6787C99.267 12.6787 97.2083 11.9402 96.7391 10.2811C95.8869 7.6053 100.569 7.04903 100.244 10.0509C100.023 11.921 101.718 11.7963 101.996 10.6551C102.676 7.77791 97.0646 7.57651 96.9019 4.11433C96.7678 1.57284 98.8169 1.66464e-05 101.249 1.66464e-05H101.306C102.076 -0.0258424 102.833 0.19582 103.467 0.632497C104.101 1.06917 104.579 1.69788 104.83 2.42642C105.606 4.85282 102.025 5.30356 101.632 2.65658C101.287 0.508304 100.11 0.987814 100.263 2.55107C100.445 5.05419 105.031 5.56251 105.376 8.53557Z" fill="white"/>
      <path d="M111.073 5.78312V6.61749H109.369C109.311 8.5356 109.369 10.4537 109.895 10.962C110.125 11.1634 110.422 10.962 110.508 11.1826C110.748 11.921 109.551 12.6787 108.268 12.6787C107.128 12.6787 106.276 11.969 106.276 10.4057V6.61749H105.864C105.682 6.61749 105.52 6.38732 105.52 6.13797V5.78312C105.521 5.67385 105.554 5.56735 105.615 5.47695C105.677 5.38656 105.763 5.31627 105.864 5.27483C106.908 4.72817 108.143 1.29474 109.149 1.29474C109.388 1.29474 109.771 1.29476 109.675 2.33053C109.675 2.33053 109.532 3.66362 109.445 5.27483H110.709C110.872 5.27483 111.073 5.505 111.073 5.78312Z" fill="white"/>
      <path d="M118.332 11.0579C118.523 11.336 118.82 11.4415 118.782 11.7963C118.691 12.0776 118.506 12.3189 118.258 12.4796C118.011 12.6403 117.715 12.7106 117.422 12.6787C116.464 12.6787 115.784 11.7196 115.258 11.7196C114.731 11.7196 114.3 12.6787 113.084 12.6787C112.721 12.6764 112.363 12.5858 112.042 12.4148C111.721 12.2437 111.446 11.9973 111.241 11.6966C111.035 11.3958 110.906 11.0497 110.864 10.688C110.821 10.3262 110.867 9.95949 110.997 9.61929C111.37 8.10399 112.912 8.16152 114.099 7.62445C114.328 7.5001 114.527 7.32589 114.68 7.11463C114.833 6.90337 114.936 6.66043 114.983 6.40371C115.03 6.14699 115.019 5.88302 114.95 5.63121C114.882 5.37941 114.758 5.14616 114.588 4.94868C114.042 4.59383 114.042 5.98448 113.879 6.58868C113.448 8.18071 110.451 7.25042 111.964 5.02542C112.252 4.65221 112.627 4.35555 113.056 4.16155C113.485 3.96756 113.955 3.88218 114.425 3.91292C118.782 3.91292 117.096 9.44666 118.332 11.0579ZM114.693 10.8852C114.894 10.8373 114.99 10.5591 115.018 10.4057C115.125 9.71926 115.125 9.02059 115.018 8.33417C115.018 8.10399 114.817 8.05603 114.588 8.38211C114.185 8.93836 113.486 9.26442 113.573 10.0796C113.579 10.2122 113.615 10.3417 113.679 10.4583C113.742 10.5749 113.831 10.6757 113.939 10.7531C114.046 10.8306 114.17 10.8827 114.301 10.9055C114.431 10.9284 114.565 10.9215 114.693 10.8852Z" fill="white"/>
      <path d="M121.539 17.8C120.227 17.8 118.436 17.5506 117.603 16.5916C115.775 14.4913 118.791 12.2471 119.643 14.1172C120.495 15.9874 121.682 16.6108 122.458 16.6108C124 16.6108 124.431 14.3666 123.779 13.9638C123.128 13.561 122.736 14.52 121.252 14.3666C120.806 14.3539 120.378 14.1919 120.035 13.9065C119.692 13.621 119.455 13.2287 119.361 12.7922C119.267 12.3558 119.322 11.9004 119.518 11.499C119.713 11.0976 120.037 10.7735 120.438 10.5783C119.876 10.2875 119.402 9.85127 119.065 9.31496C118.728 8.77865 118.541 8.16183 118.523 7.52853C118.58 6.51751 119.033 5.57003 119.785 4.89272C120.536 4.21541 121.525 3.86321 122.535 3.91292C122.856 3.92325 123.176 3.95854 123.492 4.01843C123.904 3.01142 125.168 1.94686 126.125 2.05236C127.897 2.25376 127.083 4.92952 126.125 4.57467C125.661 4.42958 125.17 4.39347 124.689 4.46916C125.243 4.7671 125.709 5.20758 126.037 5.74527C126.365 6.28295 126.544 6.89846 126.556 7.52853C126.492 8.53443 126.035 9.47464 125.284 10.1458C124.534 10.8171 123.549 11.1653 122.544 11.1154C122.356 11.1297 122.167 11.1297 121.979 11.1154C121.022 11.6429 120.428 13.4075 121.261 13.6089C122.525 13.9446 123.377 10.9907 125.091 10.9907C127.954 10.962 128.806 17.8 121.539 17.8ZM121.759 7.54773C121.759 9.01508 122.123 10.1755 122.554 10.1755C122.985 10.1755 123.349 9.01508 123.349 7.54773C123.349 6.08038 122.985 4.94868 122.554 4.94868C122.123 4.94868 121.759 6.10916 121.759 7.54773Z" fill="white"/>
      <path d="M133.594 8.98629V9.24522C133.269 11.2113 131.909 12.6786 130.3 12.6786C128.462 12.6786 126.939 10.703 126.939 8.30534C126.939 5.90771 128.462 3.9129 130.3 3.9129C130.892 3.89507 131.474 4.06431 131.964 4.39658C132.453 4.72885 132.826 5.20725 133.029 5.76386C133.12 6.15041 133.129 6.55151 133.057 6.94192C132.985 7.33234 132.834 7.70365 132.611 8.03245C132.389 8.36124 132.102 8.64043 131.767 8.85245C131.431 9.06447 131.056 9.20471 130.664 9.26439C130.364 9.27979 130.065 9.20999 129.802 9.063C130.339 10.7605 131.966 10.5304 133.125 8.70816C133.355 8.28617 133.623 8.56431 133.594 8.98629ZM129.582 7.67239C129.566 7.91828 129.566 8.16496 129.582 8.41085C129.606 8.36227 129.647 8.3245 129.697 8.30534C130.779 7.34629 130.655 4.90072 130.214 4.90072C129.774 4.90072 129.582 6.58866 129.582 7.67239V7.67239Z" fill="white"/>
      <path d="M152.831 0.834358C152.72 1.06175 152.567 1.26657 152.381 1.43857C151.309 2.39762 149.719 5.17887 149.394 8.81368C149.212 10.9044 148.743 12.6499 147.479 12.6499C145.889 12.6499 144.96 11.1538 144.348 9.2932C144.146 11.1538 143.735 12.6499 142.231 12.6499C138.401 12.6499 138.401 3.75947 137.386 2.36885C137.098 1.9937 136.863 1.58063 136.687 1.14126C136.419 0.18221 137.52 -0.0287829 138.602 -0.0287829H138.746C139.866 -0.0287829 140.929 0.18221 141.207 1.14126C141.284 1.54683 141.284 1.96328 141.207 2.36885C141.006 3.26076 141.532 7.57647 142.902 9.41785C142.988 8.6602 143.141 7.99847 143.227 7.39427C143.406 6.39197 143.406 5.36596 143.227 4.36366C143.125 3.67153 142.918 2.99896 142.614 2.36885C142.313 2.00108 142.074 1.58641 141.906 1.14126C141.638 0.18221 142.72 -0.0287829 143.821 -0.0287829H143.964C144.455 -0.0395672 144.943 0.0317453 145.41 0.18221C145.64 0.244341 145.849 0.365565 146.017 0.533835C146.185 0.702104 146.306 0.911546 146.368 1.14126C146.434 1.47362 146.434 1.81589 146.368 2.14825C146.381 2.22116 146.381 2.29594 146.368 2.36885C146.186 3.26076 146.713 7.57647 148.063 9.41785C148.283 5.19803 149.892 2.78124 149.384 1.40979C149.296 1.2215 149.259 1.0128 149.279 0.805587C149.384 0.354833 150.303 -0.0287829 151.194 -0.0287829C152.084 -0.0287829 153.013 0.383603 152.831 0.834358Z" fill="white"/>
      <path d="M155.531 11.1634C155.694 11.4127 155.914 11.4895 155.876 11.7964C155.809 12.554 154.411 12.6787 153.712 12.6787C153.013 12.6787 151.682 12.554 151.615 11.7964C151.615 11.4607 151.778 11.336 151.941 11.1154C152.343 9.92105 152.366 8.63131 152.008 7.42308C151.835 7.097 151.577 6.8956 151.615 6.52157C151.682 5.76392 152.573 5.22684 153.262 4.77609C153.951 4.32533 155.608 3.10735 155.34 4.72815C155.225 5.45702 154.698 9.94538 155.531 11.1634ZM152.151 2.05238C152.151 1.01661 152.755 0.182251 153.492 0.182251C154.229 0.182251 154.832 1.01661 154.832 2.05238C154.832 3.08816 154.229 3.91295 153.492 3.91295C152.755 3.91295 152.151 3.07857 152.151 2.05238Z" fill="white"/>
      <path d="M161.516 5.78312V6.61749H159.812C159.812 8.5356 159.812 10.4537 160.338 10.962C160.578 11.1634 160.865 10.962 160.951 11.1826C161.191 11.921 159.994 12.6787 158.71 12.6787C157.571 12.6787 156.728 11.969 156.728 10.4057V6.61749H156.307C156.125 6.61749 155.962 6.38732 155.962 6.13797V5.78312C155.964 5.67385 155.997 5.56735 156.058 5.47695C156.12 5.38656 156.206 5.31627 156.307 5.27483C157.36 4.72817 158.586 1.29474 159.601 1.29474C159.831 1.29474 160.214 1.29476 160.128 2.33053C160.128 2.33053 159.974 3.66362 159.888 5.27483H161.152C161.353 5.27483 161.516 5.505 161.516 5.78312Z" fill="white"/>
      <path d="M170 11.0866C170.201 11.336 170.469 11.4415 170.441 11.7963C170.364 12.554 169.483 12.6787 168.736 12.6787C167.989 12.6787 166.735 12.2759 166.735 10.2043V6.72294C166.735 3.56766 164.82 6.21466 165.021 9.29322C165.007 9.9365 165.134 10.575 165.394 11.1634C165.557 11.4127 165.777 11.4894 165.739 11.7963C165.672 12.554 164.274 12.6787 163.575 12.6787C162.876 12.6787 161.545 12.554 161.478 11.7963C161.478 11.4607 161.641 11.336 161.804 11.1154C162.177 10.6263 162.196 8.5835 162.196 7.14492C162.196 5.50494 162.254 4.26779 161.871 3.53891C161.689 3.18406 161.44 3.01143 161.478 2.57986C161.545 1.8222 162.483 1.31389 163.125 0.853544C163.767 0.3932 165.471 -0.834375 165.203 0.805604C165.145 1.16045 164.983 3.63479 164.944 6.06119C165.308 4.56507 166.189 3.88415 166.773 3.88415C169.607 3.91292 169.224 10.0988 170 11.0866Z" fill="white"/>
      <path fillRule="evenodd" clipRule="evenodd" d="M9.91821e-05 3.64441H2.67029e-05L0 81.3276L2.67029e-05 89H7.66026H240.34H248V3.64441H240.34H177.143V11.3168H240.34V81.3276H7.66026V11.3168H67.0271V3.64441H7.66026H9.91821e-05Z" fill="white"/>
    </g>
    <defs>
      <clipPath id="clip0">
        <rect width="248" height="89" fill="white"/>
      </clipPath>
    </defs>
  </svg>

)

export default Logo
