import React, { useEffect, useRef, useState } from 'react'

import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import VimeoEmbed from '../../components/vimeo-embed'
import useWindowDimensions from '../../components/useWindowDimensions'

import { useInView } from 'react-intersection-observer'

const ProductItem = ({ post, props }) => {

  const [productInView, setProductInView] = useState(false)

  const { width } = useWindowDimensions()

  const { ref, inView } = useInView({
    threshold: 0.9,
  });

  const productVideo = useRef(null)

  const videoPlay = (e) => {
    let playPromise = productVideo.current.play()
    if  (playPromise !== undefined) {
      playPromise.then(_ => {
        // Automatic playback started!
        // Show playing UI.
      })
      .catch(error => {
        // Auto-play was prevented
        // Show paused UI.
      })
    }
  }
  const videoPause = (e) => {
    productVideo.current.pause()
    // productVideo.current.currentTime = 0
  }

  useEffect(() => {
    // This is important because inView will be undefined on render
    if (width < 768 && inView !== undefined) {
      if (inView !== productInView) {
        if (inView === true) {
          videoPlay(ref)
          setProductInView(inView)
        } else {
          videoPause(ref)
          setProductInView(inView)
        }
      }
    }
  }, [width, inView, productInView, ref])

  const featuredImage = getImage(post.frontmatter.featuredImage)

  return (
    <div role="listitem" className={inView ? "product product--active bg-black transition-all duration-300 ease-out w-full md:w-1/2 2xl:w-1/3 relative pb-24 z-30 transform-gpu hover:shadow-xl md:hover:scale-103 hover:z-40 focus:shadow-xl md:focus:scale-103 focus:z-40 card-visible" : "product bg-black transition-all duration-300 ease-out w-full md:w-1/2 2xl:w-1/3 relative pb-24 z-30 transform-gpu hover:shadow-xl md:hover:scale-103 hover:z-40 focus:shadow-xl md:focus:scale-103 focus:z-40" } ref={ref} onMouseOver={videoPlay} onMouseLeave={videoPause} onFocus={videoPlay} onBlur={videoPause} id={post.frontmatter.anchor}>
      <div className="product__background relative overflow-hidden">
        <GatsbyImage
          alt={post.frontmatter.featuredImageAltText}
          image={featuredImage}
          width={960}
          height={960}
        />
        <video className="absolute bottom-0 top-0 h-full" playsInline loop muted="muted" ref={productVideo}>
          <source src={post.frontmatter.featuredVideo.publicURL} type="video/mp4"></source>
        </video>
      </div>
      <div className="product__content-wrapper absolute z-20 bottom-5 sm:bottom-10 md:bottom-8 lg:bottom-10 w-full">
        <div className="product__content sm:flex md:block lg:flex justify-between flex-row-reverse items-end px-5 sm:px-10 md:px-8 lg:px-10">
          <div className="w-8/12 lg:w-6/12 mb-4 sm:mb-0 md:mb-4 lg:mb-0"><VimeoEmbed src={post.frontmatter.interviewUrl} title={post.frontmatter.title + ' Interview'}></VimeoEmbed></div>
          <div className="sm:w-1/2 md:w-full lg:w-6/12 pr-4 2xl:pr-6">
            <h2 className="font-wide font-bold text-xl sm:text-2xl md:text-xl lg:text-3xl mb-1 md:mb-2"><a href={post.frontmatter.url} target="_blank" rel="noreferrer">{post.frontmatter.title}</a></h2>
            <p className="font-wide font-normal text-sm sm:text-lg md:text-base mb-5">{post.frontmatter.location}, {post.frontmatter.year}</p>
            <div>
              <a href={post.frontmatter.url} target="_blank" rel="noreferrer" className="product__link inline-block font-extrabold font-wide text-xs uppercase tracking-tighter leading-none py-4 px-4 lg:text-sm lg:py-5 lg:px-6 text-black bg-white transition-all duration-300 ease-out hover:shadow-xl hover:bg-gray-300 focus:shadow-xl focus:bg-gray-300">{post.frontmatter.buttonText}</a>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ProductItem