import * as React from "react"

import Layout from '../components/layout'
import Seo from '../components/seo'
import Header from '../components/header'
import Slideshow from '../components/slideshow.js'
import VimeoEmbed from '../components/vimeo-embed'
import ProductList from '../components/products/product-list.js'
import { AnchorLink } from 'gatsby-plugin-anchor-links'

// markup
const IndexPage = () => (
  <Layout>
    <Seo title="Home" />

    <section className="hero transition-all duration-200 ease-out relative overflow-hidden p-5 pb-10 sm:p-10 sm:pt-5 md:p-8 md-h:p-8 lg:p-10 md-h:h-auto lg:h-screen">

      <Slideshow></Slideshow>

      <Header />

      <div className="hero__content transition-all duration-200 ease-out relative md-h:pb-5 md-h:pt-16 md-h:relative md-h:max-w-screen-lg md-h:m-auto md-h:relative lg:absolute lg:bottom-10 md-h:block lg:grid lg:grid-cols-10 lg:gap-8">
        <div className="
          hero__video
          transition-all
          duration-200
          ease-out
          mb-6
          md-h:mb-6
          lg:mb-0 
          xl:mb-3 
          2xl:mb-0 
          2xl-w-xlh:mb-3 
          2xl-wh:mb-3 
          md:col-span-4
          md-h:col-span-4
          lg:col-span-3 
          lg-wh:col-span-4 
          xl:col-span-2 x
          l-wh:col-span-4 
          2xl:col-span-2 
          2xl-wh:col-span-3 
          2xl-w-xlh:col-span-3 
          3xl:col-span-2 
          3xl-wh:col-span-3
          3xl-w-xlh:col-span-3
        ">
          <VimeoEmbed src="https://player.vimeo.com/video/604367049?h=b12c688c48"></VimeoEmbed>
        </div>
        <h1 className="
          transition-all
          duration-200
          ease-out
          font-wide
          font-bold
          uppercase
          text-3xl 
          md:text-4xl
          md-h:text-4xl
          lg:text-4xl
          lg-wh:text-6xl
          2xl:text-5xl
          2xl-wh:text-7xl
          tracking-tighter
          leading-extra-tight
          lg:leading-extra-tight
          lg-wh:leading-extra-tight
          xl:leading-extra-tight
          2xl:leading-extra-tight
          2xl:leading-extra-tight
          2xl-wh:leading-extra-tight
          mb-6
          md-h:mb-6
          lg:mb-0
          xl:mb-3
          2xl:mb-1
          2xl-wh:mb-3
          2xl-w-xlh:mb-3
          lg:col-span-7
          xl:col-span-6
          2xl:col-span-6
          2xl-wh:col-span-5
          lg:col-start-1
          xl:col-start-1
          2xl:col-start-1
          lg-wh:col-start-1
          xl-wh:col-start-1
          2xl-wh:col-start-1
        ">Iconic Moments in <span style={{whiteSpace: "nowrap"}}>Music History</span></h1>
        <p className="leading-relaxed transition-all duration-200 ease-out font-medium mb-6 md-h:mb-6 lg:mb-1 2xl:mb-0 2xl-w-xlh:mb-1 2xl-wh:mb-1 lg:col-span-6 xl:col-span-5 xl-wh:col-span-4 2xl-wh:col-span-3 lg:col-start-1 xl:col-start-1 2xl:col-start-1 lg-wh:col-start-1 xl-wh:col-start-1 2xl-wh:col-start-1">The one of a kind NFTs in the On Stage with Henry Diltz collection are taken directly from the archives of historic rock n roll photographer, Henry Diltz. Meticulously animated and brought to life, each photograph will be minted only once, and never replicated.</p>
        <p className="font-bold transition-all duration-200 ease-out font-wide md-h:text-base lg:text-lg lg:col-span-4 lg:col-start-1">
          <AnchorLink
            to={"#aboutHenryDiltz"}
            title='Learn More About Henry Diltz'
            className='text-blue arrow-link'
          />
        </p>
      </div>

    </section>

    <ProductList></ProductList>

    <section id="aboutHenryDiltz" className="about transition-all duration-200 ease-out px-5 py-20 sm:px-16 sm:py-24 md:px-24 md:py-24 lg:px-0 lg:py-32 xl:py-36 lg:grid lg:grid-cols-10 lg:gap-x-8">
      <h2 className="transition-all duration-200 ease-out font-wide font-bold uppercase text-4xl sm:text-5xl md:text-6xl xl:text-7xl 2xl:text-8xl tracking-tighter leading-extra-tight md:leading-extra-tight lg:leading-extra-tight xl:leading-extra-tight 2xl:leading-extra-tight mb-9 2xl:mb-11 lg:col-start-2 lg:col-span-4">Henry Diltz</h2>
      <div className="transition-all duration-200 ease-out lg:grid lg:grid-cols-10 lg:gap-x-8 lg:col-start-1 lg:col-span-10">
        <div className="transition-all duration-200 ease-out lg:col-start-2 lg:col-span-4">
          <p className="leading-body font-medium mb-8">Henry Diltz is an extraordinary rock n roll photographer unlike any other. A founding member of the Modern Folk Quartet, Diltz is as much at home as a musician on tour, as he is a visual historian of the last five decades of popular music. The rapport he’s developed with his musician friends, along with his down-to-earth-grin and frequent laugh, enables him to capture the candid shots that convey a rare feeling of trust and intimacy with his subjects.</p>
          <p className="leading-body font-medium mb-8">For Diltz, the pictures began with a $20 second-hand Japanese camera purchased on tour with the Modern Folk Quartet. When MFQ disbanded, he embarked on his photographic career with an album cover for The Lovin’ Spoonful. Despite his lack of formal training, Diltz easily submerged himself in the world of music: the road, the gigs, the humor, the social consciousness, the psychedelia, the up and down times.</p>
          <p className="leading-body font-medium">For over 50 years, his work has graced hundreds of album covers and has been featured in books, magazines and newspapers. His unique artistic style has produced powerful photographic essays of Woodstock , The Monterey Pop Festival, The Doors, Crosby, Stills, Nash & Young, Jimi Hendrix and scores of other legendary artists. Diltz continues his distinguished career, generating new and vibrant photographs that inspire the rock n’ roll fan in each of us. Henry Diltz is a partner in, and is exclusively published and represented by the Morrison Hotel Gallery.</p>
        </div>
        <aside className="about__quotes mt-16 lg:mt-0 lg:col-start-7 lg:col-span-3">
          <figure className="transition-all duration-200 ease-out text-lg 2xl:text-xl font-wide mb-16 xl:mb-24">
            <blockquote>
              <p className="font-light italic mb-3">“This is not history, this is evidence!”</p>
            </blockquote>
            <figcaption className="font-extrabold">Glenn Frey, Eagles</figcaption>
          </figure>
          <figure className="transition-all duration-200 ease-out text-lg 2xl:text-xl font-wide mb-16 xl:mb-24">
            <blockquote>
              <p className="font-light italic mb-3">“I’m the drummer, you’re the photographer, it’s as simple as that.”</p>
            </blockquote>
            <figcaption className="font-extrabold">Ringo Starr</figcaption>
          </figure>
          <figure className="transition-all duration-200 ease-out text-lg 2xl:text-xl font-wide mb-0">
            <blockquote>
              <p className="font-light italic mb-3">“Early on in his career, Henry mastered the art of capturing the moment. What he has proved over the decades is the illusive art of capturing life”</p>
            </blockquote>
            <figcaption className="font-extrabold">Gerry Beckley, America</figcaption>
          </figure>
        </aside>
      </div>
    </section>

  </Layout>
)

export default IndexPage
                    