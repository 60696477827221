/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

 import React from 'react'
 import PropTypes from 'prop-types'
 import { Helmet } from 'react-helmet'

 import Fonts from '../components/fonts'
 import Footer from '../components/footer'
 import PartnerBlock from './assets/partner-block.js'
 
 const Layout = ({ children }) => {
 
   return (
     <div>
      <div className="overflow-hidden text-white bg-black">
        <Helmet htmlAttributes={{ class : 'bg-black' }}/>
        <Fonts></Fonts>
        <div className="site-wrapper__content m-auto bg-black text-white min-h-screen">
          <main>{children}</main>
        </div>
        <PartnerBlock />
        <Footer></Footer>
      </div>
    </div>
   )
 }
 
 Layout.propTypes = {
   children: PropTypes.node.isRequired,
 }
 
 export default Layout
 