import React from 'react'

const SparkartLogo = () => ( 
  <svg className="w-full h-auto" width="800" height="220" viewBox="0 0 800 220" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fillRule="evenodd" clipRule="evenodd" d="M779.24 72.7045L783.208 72.6899C783.296 70.7722 783.12 56.9532 783.267 56.5872C783.486 56.9385 787.352 72.0311 787.513 72.6899H791.656C792.11 70.6258 793.208 66.8051 793.779 64.58C794.057 63.5406 795.697 56.9678 795.946 56.5579L795.975 72.7045L799.958 72.6899V52.0345L793.486 52.0052L789.665 66.0146C789.372 65.7218 786.107 53.4691 785.712 51.9906L779.24 52.0052V72.7045V72.7045Z" fill="#FEFEFE"/>
    <path fillRule="evenodd" clipRule="evenodd" d="M759.43 55.4454L765.799 55.4746V72.646C766.429 72.8216 769.181 72.6753 770.089 72.7045V55.4893L776.458 55.4746L776.473 52.0492C775.975 51.9174 765.214 52.0052 763.72 52.0052C762.534 52.0052 760.499 51.8881 759.43 52.0199V55.4454V55.4454Z" fill="#FEFEFE"/>
    <path fillRule="evenodd" clipRule="evenodd" d="M452.518 111.439C458.77 101.704 466.428 92.3059 473.382 82.3661C479.005 74.3148 490.835 60.3493 494.495 51.9906L470.337 52.2834C468.814 56.1041 436.618 102.377 434.29 103.431L434.334 5L415.637 5.08783L415.842 174.898L434.261 174.883L434.29 121.701C437.101 123.018 470.966 168.984 475.432 174.913L499.195 175.118C495.564 167.052 454.715 118.319 452.518 111.439Z" fill="#FEFEFE"/>
    <path fillRule="evenodd" clipRule="evenodd" d="M743.501 155.546C717.468 154.111 719.577 152.237 719.577 126.136C719.738 100.182 719.752 84.7523 719.694 72.7777H743.237V51.9906H719.547C719.445 40.6455 719.342 28.0269 719.357 5.0293L700.719 5.16105C700.763 40.5723 700.792 76.0275 700.792 111.439C700.792 126.253 698.552 149.09 705.155 160.947C711.685 172.644 726.4 177.562 743.633 174.561L743.501 155.546V155.546Z" fill="#FEFEFE"/>
    <path fillRule="evenodd" clipRule="evenodd" d="M342.005 174.898L360.951 174.708L361.112 102.875C361.112 73.2169 358.96 70.011 403.836 71.548L403.777 51.0537C381.112 52.1077 346.032 45.0664 342.181 82.0294L342.005 174.898V174.898Z" fill="#FEFEFE"/>
    <path fillRule="evenodd" clipRule="evenodd" d="M625.711 175.015L644.657 174.81L644.818 102.992C644.818 73.3341 642.666 70.1282 687.541 71.6652L687.483 51.1709C664.818 52.2103 629.737 45.1836 625.887 82.132L625.711 175.015Z" fill="#FEFEFE"/>
    <path fillRule="evenodd" clipRule="evenodd" d="M311.405 174.839H329.838V117.367C329.911 116.123 329.955 114.864 329.955 113.591C329.955 78.3697 301.39 49.824 266.163 49.824C245.563 49.824 227.232 59.6027 215.562 74.7685C219.97 81.883 223.22 89.7879 225.109 98.1906C231.346 81.5756 247.378 69.7474 266.163 69.7474C290.38 69.7474 310.014 89.378 310.014 113.591C310.014 137.803 290.38 157.434 266.163 157.434C247.73 157.434 231.961 146.06 225.475 129.942C223.791 138.403 220.716 146.367 216.485 153.584C228.183 168.076 246.09 177.357 266.163 177.357C283.85 177.357 299.853 170.17 311.405 158.546V174.839V174.839Z" fill="#FEFEFE"/>
    <path fillRule="evenodd" clipRule="evenodd" d="M86.2947 215.243H105.328V160.581C116.91 172 132.81 179.041 150.351 179.041C185.768 179.041 214.465 150.349 214.465 114.937C214.465 79.5408 185.768 50.8341 150.351 50.8341C114.948 50.8341 86.2361 79.5408 86.2361 114.937C86.2361 115.816 86.2654 116.665 86.2947 117.529V215.243ZM150.351 71.3577C174.436 71.3577 193.953 90.8713 193.953 114.937C193.953 139.018 174.436 158.532 150.351 158.532C126.28 158.532 106.763 139.018 106.763 114.937C106.763 90.8713 126.28 71.3577 150.351 71.3577Z" fill="#FEFEFE"/>
    <path fillRule="evenodd" clipRule="evenodd" d="M595.945 174.752H614.379V117.294C614.452 116.035 614.481 114.776 614.481 113.503C614.481 78.2819 585.931 49.7362 550.704 49.7362C515.476 49.7362 486.926 78.2819 486.926 113.503C486.926 148.724 515.476 177.284 550.704 177.284C568.39 177.284 584.393 170.082 595.945 158.459V174.752V174.752ZM550.704 69.6596C574.92 69.6596 594.554 89.2903 594.554 113.503C594.554 137.715 574.92 157.346 550.704 157.346C526.487 157.346 506.853 137.715 506.853 113.503C506.853 89.2903 526.487 69.6596 550.704 69.6596Z" fill="#FEFEFE"/>
    <path d="M75.7819 138.389C71.9312 90.6663 18.9589 113.444 18.9589 84.7083C18.9589 74.6661 27.2751 68.6496 41.038 68.6496C53.366 68.6496 63.6882 70.9479 71.7116 75.5298V58.0511L68.2709 56.0309C63.6882 53.7473 52.5022 50.8781 41.038 50.8781C29.852 50.8781 20.1155 54.0254 12.0774 60.3347C4.05397 66.3513 0.0422363 74.6661 0.0422363 84.7083C0.0422363 107.925 20.7451 119.388 38.1244 122.008C50.7745 123.926 56.7628 128.552 56.7628 140.116C56.7628 148.241 51.5505 158.268 35.5036 158.268C23.7466 158.268 13.0584 157.317 3.87827 150.729V170.506L7.31898 172.512C11.7553 175.088 23.6734 178.236 35.1375 178.236C61.7994 178.236 77.5974 160.962 75.7819 138.389V138.389Z" fill="#FEFEFE"/>
  </svg>
)

export default SparkartLogo
