import React from 'react'
import { Helmet } from 'react-helmet'

const Fonts = () => {
  return (
    <Helmet>
      <link rel="stylesheet" href="https://use.typekit.net/poc2wjx.css"></link>
    </Helmet>
  )
}

export default Fonts