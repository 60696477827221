import React from 'react'

const PartnerBlock = () => {
  return (
    <section className="pt-6 bg-lightBlack">
      <h3 className="text-center uppercase font-bold mb-2">For media inquiries:</h3>
      <div className="partner-logo flex justify-center"><a href="http://www.workhousepr.com" target="_blank" rel="noreferrer" className="inline-block w-32 lg:w-36 xl:40 transition-all duration-300 ease-out hover:opacity-80 focus:opacity-80"><img src="/images/workhouse-logo.gif" className="rounded" alt="Workhouse" /></a></div>
      <p className="text-center text-xs text-gray-300 py-6">Adam Nelson<br></br><a className="hover:opacity-80 focus:opacity-80  transition-all duration-300 ease-out" href="mailto:nelson@workhousepr.com">nelson@workhousepr.com</a><br></br>Tel: +1 212.645.8006<br></br><a className="hover:opacity-80 focus:opacity-80 transition-all duration-300 ease-out" target="_blank" rel="noreferrer" href="http://www.workhousepr.com">http://www.workhousepr.com</a></p>
    </section>
  )
}

export default PartnerBlock