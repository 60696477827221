import React from 'react'

import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import VimeoEmbed from '../../components/vimeo-embed'

const ProductItemInactive = ({ post, props }) => {

  const featuredImage = getImage(post.frontmatter.featuredImage)

  return (
    <div role="listitem" className="product product--inactive bg-black w-full md:w-1/2 2xl:w-1/3 relative pb-24 z-10 card-visible" id={post.frontmatter.anchor}>
      <div className="product__background relative overflow-hidden">
        <GatsbyImage
          alt={post.frontmatter.featuredImageAltText}
          image={featuredImage}
          width={960}
          height={960}
        />
      </div>
      <div className="product__content-wrapper absolute z-20 bottom-5 sm:bottom-10 md:bottom-8 lg:bottom-10 w-full">
        <div className="product__content sm:flex md:block lg:flex justify-between flex-row-reverse items-end px-5 sm:px-10 md:px-8 lg:px-10">
          <div className="w-8/12 lg:w-6/12 mb-4 sm:mb-0 md:mb-4 lg:mb-0"><VimeoEmbed src={post.frontmatter.interviewUrl}></VimeoEmbed></div>
          <div className="sm:w-1/2 md:w-full lg:w-6/12 pr-4 2xl:pr-6">
            <h2 className="font-wide font-bold opacity-40 text-xl sm:text-2xl md:text-xl lg:text-3xl mb-1 md:mb-2">{post.frontmatter.title}</h2>
            <p className="font-wide font-normal opacity-40 text-sm sm:text-lg md:text-base mb-5">{post.frontmatter.location}, {post.frontmatter.year}</p>
            <div>
              <span className="product__link opacity-40 inline-block font-extrabold font-wide text-xs uppercase tracking-tighter leading-none py-4 px-4 lg:text-sm lg:py-5 lg:px-6 text-black bg-white">{post.frontmatter.buttonText}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ProductItemInactive