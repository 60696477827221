import React from 'react'

import { useStaticQuery, graphql } from 'gatsby'

import ProductItem from './product-item.js'
import ProductItemInactive from './product-item-inactive.js'

export default function ProductList() {
  const data = useStaticQuery(graphql`
    query {
      allMarkdownRemark(sort: { order: ASC, fields: [frontmatter___priority] }) {
        edges {
          node {
            id
            frontmatter {
              url
              title
              anchor
              year
              location
              active
              interviewUrl
              buttonText
              featuredImageAltText
              featuredVideo {
                publicURL
              }
              featuredImage {
                childImageSharp {
                  gatsbyImageData(layout: FULL_WIDTH)
                }
              }
            }
          }
        }
      }
    }
  `)

  const Products = data.allMarkdownRemark.edges.map((edge) => (
    edge.node.frontmatter.active
    ? <ProductItem key={edge.node.id} post={edge.node} />
    : <ProductItemInactive key={edge.node.id} post={edge.node} />
  ))
  return <div id="the-collection" className="flex flex-wrap" role="list">{Products}</div>
}