import React, { useState, useEffect, useRef } from 'react'

import { useStaticQuery, graphql } from 'gatsby'
import 'node-self'

import { AnchorLink } from 'gatsby-plugin-anchor-links'

import { Splide } from '@splidejs/react-splide';
import Video from "@splidejs/splide-extension-video";
import '@splidejs/splide/dist/css/themes/splide-default.min.css';
import '@splidejs/splide-extension-video/dist/css/splide-extension-video.min.css';

import { GatsbyImage, getImage } from 'gatsby-plugin-image'

export default function Slideshow() {

  const [slideNumber, setSlideNumber] = useState(0)

  const slideContainer = useRef(null)

  const data = useStaticQuery(graphql`
    query {
      allMarkdownRemark(sort: { order: ASC, fields: [frontmatter___priority] }) {
        edges {
          node {
            id
            frontmatter {
              title
              anchor
              shortName
              featuredVideo {
                publicURL
              }
              featuredVideoShort {
                publicURL
              }
              featuredImage {
                publicURL
                childImageSharp {
                  gatsbyImageData(layout: FULL_WIDTH)
                }
              }
            }
          }
        }
      }
    }
  `)


  useEffect(() => {
    //Get poster images
    const posters = data.allMarkdownRemark.edges.map((edge) => (
      edge.node.frontmatter.featuredImage.publicURL
    ))

    const videoContainers = slideContainer.current.querySelectorAll('video')
    videoContainers.forEach(function (video, index) {
      video.setAttribute('poster', posters[index])
    })
  })

  // Return if SSR to avoid 'window is undefined' erroe
  if (typeof window === "undefined") {
    return <p>Server Render</p>
  }

  //Create Slides
  const Slides = data.allMarkdownRemark.edges.map((edge, index) => (
    <li className="splide__slide" key={index} data-splide-html-video={edge.node.frontmatter.featuredVideoShort.publicURL}>
        <GatsbyImage
          image={getImage(edge.node.frontmatter.featuredImage)}
          width={960}
          height={960}
          alt={""}
        />
    </li>
  ))

  //Create Slide options
  const SlideOptions = {
    type : 'fade',
    height  : '100vh',
    autoplay: true,
    pauseOnHover: false,
    rewind: true,
    arrows: false,
    pagination: false,
    video: {
      autoplay: true,
      mute: true,
      disableOverlayUI: true,
      hideControls: true,
      interval: 4090,
      loop: true,
      playsInline: true
    } 
  }

  //Compare current slide to index
  const compareSlides = function (index, slideNumber) {
    return index === slideNumber
  }

  // Create slideshow nav items
  const navItems = data.allMarkdownRemark.edges.map((edge, index) => (
    <li className={compareSlides(index, slideNumber) ? "active-slide hero-slide uppercase font-wide leading-none mb-5 last:mb-0" : 'hero-slide uppercase font-wide leading-none mb-5 last:mb-0'} key={index}>
        <AnchorLink
          to={"#" + edge.node.frontmatter.anchor}
          title={(edge.node.frontmatter.shortName) ? edge.node.frontmatter.shortName : edge.node.frontmatter.title}
        />
    </li>
  ))

  return (
    <div>
      <div className="hero__slider z-0 absolute top-0 right-0 h-full w-full">
        <div ref={slideContainer} className="hero__slider-container h-full w-screen-h hidden md-h:hidden lg:block absolute top-0 right-0">
          <Splide
            options={SlideOptions}
            Extensions={{ Video }}
            onMove={ ( splide, newIndex, oldIndex ) => {
                setSlideNumber(newIndex)
            } }
          >
            {Slides}
          </Splide>
        </div>
      </div>
      <div className="hero__nav hidden md-h:hidden lg:block absolute bottom-10 right-10 text-right z-30">
        <h2 className="font-wide font-extrabold uppercase inline-block text-lg border-b-2 pb-2 mb-7">The Collection:</h2>
        <ul>
          {navItems}
        </ul>
      </div>
    </div>
  )
}