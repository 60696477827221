import React from 'react'
import PropTypes from 'prop-types'

import { AnchorLink } from 'gatsby-plugin-anchor-links'

import Logo from '../components/assets/logo'

const Header = () => {

  return (
    <header className="flex flex-wrap relative justify-center items-center md:flex-nowrap md:justify-between md:mb-8">
      <div className="header__logo w-full md:w-auto">
        <div className="header__logo-img w-48 lg:w-60 m-auto mb-8 md:mb-0">
          <Logo />
        </div>
      </div>
      <nav className="header__menu z-50">
        <ul className="flex items-center mb-8 md:mb-0">
          {/* <li><a href="#" className="inline-block font-extrabold font-wide text-sm uppercase leading-none mr-10 sm:mr-20">FAQ</a></li> */}
          <li>
            <AnchorLink
              to={"#the-collection"}
              title="The Collection"
              className="inline-block text-center lg:hidden font-extrabold font-wide text-xs sm:text-sm border-2 border-white uppercase leading-none py-3 px-4 tracking-tighter mr-5 sm:mr-8 transition-all duration-300 ease-out hover:shadow-xl hover:text-black hover:bg-gray-300 hover:border-gray-300 focus:shadow-xl focus:text-black focus:bg-gray-300 focus:border-gray-300"
            />
          </li>
          <li><a href="https://superrare.com/henrydiltz" target="_blank" rel="noreferrer" className="inline-block text-center font-extrabold font-wide text-xs sm:text-sm uppercase leading-none py-3 px-4 tracking-tighter lg:py-5 lg:px-6 text-black bg-white lg:border-none lg:border-none border-2 border-white transition-all duration-300 ease-out hover:shadow-xl hover:bg-gray-300 hover:border-gray-300 focus:shadow-xl focus:bg-gray-300 focus:border-gray-300">View Auction</a></li>
        </ul> 
      </nav>
    </header>
  )
}

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
