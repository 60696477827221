import React from 'react'

import SparkartLogo from './assets/sparkart-logo'
import CurrentYear from './assets/current-year.js'

const Footer = () => {
  return (
    <footer className="pt-6">
        <div className="sparkart-logo flex justify-center"><a href="https://www.sparkart.com" className="inline-block w-32 lg:w-36 xl:40 opacity-50 transition-all duration-300 ease-out hover:opacity-100 focus:opacity-100" aria-label="Sparkart Logo"><SparkartLogo /></a></div>
        <p className="text-center text-xs text-gray-300 py-6">Copyright &copy; <CurrentYear /> Henry Diltz. All Rights Reserved.</p>
    </footer>
  )
}

export default Footer